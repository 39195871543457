.contactUsPreferenceButton {
    display: inline-block;
    text-align: center;
    padding: 14px 35px;
    border-radius: 10px;
    border: none;
    margin-right: 10px;
}

.ContactUsInput {
    padding: 5%;
}

.ContactUsSendButton {
    background: black;
    color: white;
}

.ContactUsSendButton:hover {
    background: white;
    color: black
}

.ContactUsForm {
    padding-top: 3rem;
    width: 33%;
}

@media screen and (max-width: 1024px) {
    .ContactUsForm {
        width: 80%;
    }
}