.VisitUs {
  background-image: url("/public/images/visitusphotos/newlego.webp");
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  padding-top: 100px;
}

.VSTitle {
    font-size: 120px;
    font-weight: 700;
    color: black;
    font-weight: bold;
    padding-bottom: 50px;
}

.VSTextContainer {
    font-size: 30px;
    color: black;
    text-align: center;
    line-height: 1.3;
    font-weight: 500;
    margin-bottom: 40px;
    max-width: 1200px;
}

.linkText{
  font-size: 30px;
  color: black;
  text-align: center;
  font-weight: 500;
  background-color: #D2E0FB;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 50px;
}

.linkText a {
  text-decoration: none;
  color: #0E2954;
}

.linkText:hover {
  background-color: #C9D8EB;
}

.linkText a:hover{
  text-decoration: underline;
}

.columnContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.rowContainer{
    display: flex;
    flex-direction: row;
}

.image{
  height: 450px;
  width: auto;
  border-radius: 6px;
  border: 8px white solid;
}

.slideshow-container {
    overflow: hidden;
    display: flex;
    position: relative;
    padding-bottom: 50px;
}
  
  .slide {
    display: none;
    width: 100%;
    height: auto;
  }
  
  .active {
    display: block;
  }
  
  .slide {
    animation: fade .5s ease-in-out;
  }
  
  @keyframes fade {
    from { opacity: 0; }
    to { opacity: 1; }
  }

.prevButton,
.nextButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px; 
  background-color: rgba(39, 55, 77, 0.8); /* Button background color */
  color: aliceblue;
  border-radius: 50px;
  border: none;
  cursor: pointer;
  z-index: 2; /* Ensure buttons are above the images */
}

.prevButton:hover, 
.nextButton:hover{
  background-color: rgba(39, 55, 77, 0.6);
}


.prevButton {
  left: 3px; /* Adjust left position */
}

.nextButton {
  right: 3px; /* Adjust right position */
}

@media screen and (max-width: 767px) {
  .VSTitle{
    font-size: 70px;
    padding-bottom: 30px;
  }

  .VSTextContainer {
    font-size: 20px;
    line-height: 1.2;
    margin-bottom: 30px;
    max-width: 300px;
  }

  .linkText{
    font-size: 25px;
    max-width: 300px;
    margin-bottom: 40px;
  }
  
  .image{
    height: 275px;
  }

  .prevButton,
  .nextButton {
  position: absolute;
  top: 35%;
  transform: translateY(-50%);
  width: 25px;
  height: 25px; 
  }
}

  
