.applicationForm {
    display: flex;
    flex-direction: row;
    margin-top: 2rem;
    width: 100%;
}

.react-datepicker__view-calendar-icon input {
    padding: 5%;
    width: 100%;
}

.react-datepicker__calendar-icon {
    display: none;
    visibility: hidden;
}

.DragDropArea {
    border: 1px dotted black;
    padding: 20px; 
    text-align: center;
    cursor: pointer; 
    backdrop-filter: blur(12px);
}

.DragDropArea button{
    margin-top: 10px;
    width: 100px;
    height: 30px;
    background-color: #BACDDB;
    border-radius: 10px;
}

.ContactUsSendButton {
    height: 80px;
    width: 100%;
    font-size: 15px;
    border: 1px solid black;
}

.ContactUsSendButton2 {
    display: none;
    visibility: hidden;
}

@media screen and (max-width: 1024px) {
    .applicationForm {
        flex-direction: column;
    }

    .ContactUsSendButton1 {
        display: none;
        visibility: hidden;
    }

    .ContactUsSendButton2 {
        display: block;
        visibility: visible;
    }
}