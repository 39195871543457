.progButtons {
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 20px;
    border-radius: 33px;
    cursor: pointer;
    transition: background-color .45s ease-in;
}

.progBttnText {
    font-size: 24px;
    font-weight: 600;
    color: black;
    transition: color 1s ease-out;
}

.progBttnTextActive {
    font-size: 24px;
    font-weight: 600;
    color: white;
}

@media screen and (max-width: 900px) {
    .progButtons {
        margin: 2px;
        padding: 6px 12px;
    }

    .progBttnText {
        font-size: 14px;
    }

    .progBttnTextActive {
        font-size: 14px;
        font-weight: 600;
        color: white;
    }
}


@media screen and (max-width: 700px) {
    .progBttnText {
        font-size: 13px;
    }

    .progBttnTextActive {
        font-size: 13px;
        font-weight: 600;
        color: white;
    }
}