@import url('https://fonts.googleapis.com/css2?family=Vollkorn:ital,wght@0,400..900;1,400..900&display=swap');

@font-face {
    font-family: 'OEGP';
    src: local('OEFP'), url('../fonts/oldEnglishGothicPixel.ttf') format('truetype');
}

.logoName{
    font-size: 1.3rem;
    font-weight: 700;
}

.footer {
    padding: 3%; 
    padding-top: 3rem;
    padding-bottom: 3rem;
    display: flex;
    flex-direction: row;
    height: 25vh; 
    width: 100vw;
    color: white; 
    align-content: center;
    justify-content: space-between; 
    align-items: center;
}

.rightFooter {
    display: flex;
    flex-direction: column;
    width: auto;
}

.footerHours {
    font-size: 17px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.footerContact {
    font-size: 17px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
}

.copyrightTag {
    color: white;
    text-align: center;
    padding: 2px;
    font-size: 12px;
}

@media screen and (max-width: 1024px) {
    .copyrightTag {
        padding-bottom: 15px;
    }
}

@media screen and (max-width: 700px) {
    .logoName {
        font-size: 1.2rem;
    }

    .bttButton {
        visibility: hidden;
        display: none;
    }

    .logoName {
        font-size: 1.7rem;
        display: flex;
        justify-content: center;
    }

    .footer {
        flex-direction: column-reverse;
        height: 100%;
    }

    .rightFooter {
        vertical-align: middle; 
    }

    .footerHours {
        padding-bottom: 80px;
    }

    .footerAddy {
        justify-content: center;
    }

    .footerContact {
        padding-bottom: 80px;
        text-align: center;
    }

    .footerSocialLinks {
        display: flex;
        justify-content: center;
    }
}
