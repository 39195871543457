.Admissions {
    padding-top: 80px;
}

.AdmissionsTitle {
    text-align: center;
    font-size: 120px;
}

.AdmissionsStatus{
    background: #A5E99B;
    border-radius: 10px;
    padding: 20px;
    margin-top: 15px;
}

.QuoteBubble {
    background-color: white;
    width: 50%;
    border-radius: 33px;
    padding: 30px;
    margin-top: 60px;
    align-items: center;
    display: flex;
    flex-direction: horizontal;
    justify-content: space-between;
}

.QuoteBubble img {
    height: 250px;
    width: 250px;
    border-radius: 20px;
    object-fit: fit;
    margin-right: 15px;
    object-position: calc(50 / 540 * 100%) calc(100 / 960 * 100%);  
}

.QuoteBubble p {
    font-size: 17px;
    font-weight: 600;
    line-height: 1.3;
}

.FormMessage{
    background-color: white;
    border-radius: 33px;
    padding: 30px;
    width: 100%;
    margin-top: 110px;
    line-height: 1.3;
}

.FormMessage p {
    font-size: 17px;
}

@media screen and (max-width: 1080px) {
    .Admissions {
        padding-top: 100px;
    }

    .AdmissionsTitle {
        font-size: 60px;
    }

    .QuoteBubble img {
        width: 240px;
        height: 240px;
        object-fit: cover;
        align-self: center;
        margin-bottom: 15px;
        margin-right: 0px;
    }
    
    .QuoteBubble {
        padding: 35px;
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        width: 70%;
    }

    .FormMessage p {
       text-align: center;
    }
}
