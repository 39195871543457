.nav-wrapper {
  height: 80px;
  width: 100%;
  font-size: 1.2rem;
  position: fixed;
  top: 0;
  z-index: 999;
  backdrop-filter: blur(36px);
  -webkit-backdrop-filter: blur(36px);
  background-color: rgba(0, 0, 0, 0.071);
}

.navbar {
  display: flex;
  align-items: center;
  height: 80px;
  width: 100%;
  padding-right: 50px;
  padding-left: 50px;
}

.navbar-logo {
  color: white;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  mix-blend-mode: difference;
  position:fixed;
}

.navbar-logo::before {
  content: attr(data-text); /* Use the text content from the 'data-text' attribute */
  color: white; /* Color of the text */
  mix-blend-mode: difference; /* Blend mode */
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.navbar-icon {
  margin-right: 0.5rem;
  scale: 1.3;
}

.nav-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  text-align: center;
  justify-content: flex-end;
  position: fixed;
  right: 0;
}

.nav-item {
  height: 80px;
  border-bottom: 2px solid transparent;
}

.nav-item:hover {
  border-bottom: 2px solid white;
}

.nav-links {
  color: black;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 80%;
  background-color: white;
  border-radius: 33px;
  margin-right: 5px;
  margin-left: 5px;
  margin-top: 9px;
}

.activated {
  color: white;
  background: linear-gradient(120deg, #4286f4, black);
}

.fa-bars {
  color: #fff;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 80px;
    left: 100%;
    opacity: 1;
    transition: all 0.5s ease;
    padding: 40px;
    background: linear-gradient(300deg, #4286f455, #1ed75fff);
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);
  }

  .nav-menu.active {
    left: 0;
    opacity: 1;
    transition: all 0.3s ease;
    z-index: 1;
    border-bottom-right-radius: 33px;
    border-bottom-left-radius: 33px;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    transform: scale(1.03);
    transition: all 0.2s ease;
  }

  .nav-item:hover {
    border: none;
    border-radius: 33px;
  }

  .nav-item {
    width: 100%;
    margin-bottom: 1rem;
  }

  .navbar-logo {
    border: none;
  }

  .menu-icon {
    display: block;
    position: fixed;
    mix-blend-mode: difference;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }
}