@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.page-icon {
  margin-left: 1rem;
  font-size: 100px;
}

#error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

@media screen and (max-width: 960px) {
  .About,
  .VisitUs,
  .Contact {
    font-size: 2.5rem;
  }

  .page-icon {
    margin-left: 1rem;
    font-size: 80px;
  }
}
