.splashBg {
    background: url('/public/images/titlephoto.webp');
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}

.titleArea {
    display: flex;
    flex-direction: column;
    align-content: center;
    height: 80vh;
    align-items: center;
    color: white;
    position: relative;
    margin-top: 80px;
}

.messageTitle {
    font-size: 1.5rem;
    font-weight: 400;
    color: white;
}

.companyName {
    font-stretch: expanded;
    font-size: 7rem;
    padding-right: 10%;
    font-weight: 500;
}

.arrow {
    bottom: 5%; 
    width: 100%;
    vertical-align: middle;
    position: sticky; 
    color: white; 
    font-size: 3rem;
}

.logo {
    height: 60%;
    width: auto;
    margin-bottom: -50px;
}


@media screen and (max-width: 700px) {
    .logo {
      width: 100%;
      height: auto;
      margin-bottom: -25px;
    }
}

.message {
    width: 12%;
    bottom: 5%;
    position: sticky; 
    background-color: #2525259e; 
    backdrop-filter: blur(3px); 
    border-radius: 33px; 
    padding: 1%; 
    text-align: center;
}

.blcTitle {
    font-size: 80px;
}

.blcDesc {
    font-size: 21px;
    font-weight: 600;
}

@media screen and (max-width: 1064px) {
    .blcTitle {
        font-size: 64px;
    }

    .blcDesc {
        font-size: 19px;
    }
}

@media screen and (max-width: 700px) {
    .message {
      bottom: 15%;
      visibility: hidden;
    }

    .blcTitle {
        font-size: 40px;
    }
}
