.info-grid {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 1rem;
    padding: 3%;
    list-style-type: none;
    width: 70%;
  }
  
.info-card {
    display: flex;
    justify-content: center;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;  
}

.card-content,
.card-content2 {
    color: black;
    height: 600px;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
}

.card-content2 {
    display: none;
}

.info-card:hover .card-content{
    display: none;
  }
  
.info-card:hover .card-content2{
    display: flex;
    text-align: center;
    font-size: 17px;
    font-weight: 600;
}

.info-card-title {
    font-size: 48px;
    font-weight: 600;
    background: linear-gradient(120deg, #4286f4c9, #1ed75fca);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.card-content-handbook {
    height: 600px;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
}

.EnrollmentAnswer{
    font-size: 25px;
}

@media screen and (max-width: 767px) {
    .DisciplineAnswer{
        font-size: 15px;
    }

    .EnrollmentAnswer{
        font-size: 20px;
    }

    .SickAnswer{
        font-size: 15px;
    }

    .ClosedAnswer{
        font-size: 15px;
    }
}

@media screen and (max-width: 960px) {
    
}