:root {
    --primary: #fff
}

.btn {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
}

.btn-primary {
    background-color: white;
    color: black;
    border: 1px solid black;
}

.btn-outline {
    background-color: transparent;
    color: white;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
}

.btn-medium {
    padding: 12px 26px;
    font-size: 20px;
}

.btn-large {
    padding: 12px 26px;
    font-size: 20px;
}

.btn-one {
    background-color: rgb(213, 213, 213);
    border-radius: 33px;
    margin: 10px;
    color: white
}

.btn-medium:hover {
    background: white;
    color: black;
    transition: all 0.3s ease-out;
}

.btn-large:hover {
    background: black;
    color: white;
    transition: all 0.3s ease-out;
}
