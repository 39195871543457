
.smallText {
    color: rgba(255, 255, 255, 0.8);
    font-size: 1rem;
    padding-top: 1rem;
}

.programsTop {
    padding-top: 110px;
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: space-between;
}

.programTitle {
    font-size: 80px;
    font-weight: 700;

}

@media screen and (max-width: 1200px) {
    .programsTop {
        flex-direction: column;
        align-items: center;
    }
    
    .programTitle {
        font-size: 64px;
        text-align: center;
    }
}

@media screen and (max-width: 700px) {
    .programTitle {
        font-size: 40px;
    }
}